
$(function(){
	/*
	console.log($(window).height());
	$('.fullpage-wrap').css('min-height', $(window).height());

	$(window).resize(function(){
		$('.fullpage-wrap').css('min-height', $(window).height());
	});
	*/

	$('.nav-main a').click(function(e){

		if($(this).attr('href').indexOf('#') !== -1) {
			var href = $(this).attr('href').substring(1);
			var dst = $(href);

			$('html, body').animate({
				scrollTop: $(dst).offset().top
			}, 500, function(){
				document.location.href = href;
			});

			e.preventDefault();
			return false;
		}
	});

	/*setTimeout(function(){
		$('.sponsors').each(function(){
			var max = 0;
			$('.box', this).each(function(){
				//console.log($(this).height());
				if($(this).height() > max) max = $(this).height();
			});
			$('.box', this).height(max);
		});
	},100);*/
});